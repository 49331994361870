import React, { createContext, useState, useEffect, useContext } from "react";

type RoleContextType = {
    userRole: string;
    refreshAuth: () => void;
};

const RoleContext = createContext<RoleContextType | null>(null);

export const RoleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userRole, setUserRole] = useState('');

    const checkRole = async () => {
        const emailUser = localStorage.getItem("user email");
        if (!emailUser) return;

         try {
            const response = await fetch("/user-management/admin", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: emailUser }),
            });

             const data = await response.json();
            setUserRole(data);
        } catch {
            setUserRole('');
        }
    };

    useEffect(() => {
        checkRole(); // Run on mount
    }, []);

    return <RoleContext.Provider value={{ userRole, refreshAuth: checkRole }}>{children}</RoleContext.Provider>;
};

export const useRole = () => {
    const context = useContext(RoleContext);
    if (!context) throw new Error("useRole must be used within an RoleProvider");
    return context;
};
