import DashboardIcon from "./Dashboard";
import LinksManagementIcon from "./LinksManagement";
import QueueManagementIcon from "./QueueManagement";
import SessionsIcon from "./Sessions";
import ClouditoolsIcon from "./Clouditools";
import AdminIcon from "./Admin";

export default {
  SessionsIcon,
  DashboardIcon,
  LinksManagementIcon,
  QueueManagementIcon,
  ClouditoolsIcon,
  AdminIcon
};
