import Icons from "../../icons/sidebar";

const index = [
  // {
  //   title: "Dashboard",
  //   Icon: Icons.DashboardIcon,
  //   path: "/dashboard",
  // },
  {
    title: "Useful links",
    Icon: Icons.LinksManagementIcon,
    path: "/dashboard/links-grid",
    adminRequired: false
  },{
    title: "Queue Management",
    Icon: Icons.QueueManagementIcon,
    path: "/dashboard/queue-management",
    adminRequired: false
  },{
    title: "Clouditools",
    Icon: Icons.ClouditoolsIcon,
    path: "/dashboard/clouditools",
    adminRequired: false
  },{
    title: "Admin",
    Icon: Icons.AdminIcon,
    path: "/admin",
    adminRequired: true
  }
];

export default index;
