import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./components/authentication/AuthGuard";
import DashboardLayout from "./components/layouts/DashboardLayout";
import Coralogix from "pages/queries/Coralogix";
import Redash from "pages/queries/Redash";
import Jenkins from "pages/queries/Jenkins";
import { RoleProvider } from "./utils/RoleContext";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback>
      <Component {...props} />
    </Suspense>
  );

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboard/SaaS")));

const UsefulLinks = Loadable(
  lazy(() => import("./pages/linksManagement/UsefulLinks"))
);

const QueueManagement = Loadable(
  lazy(() => import("./pages/queueManagement/QueueManagement"))
);

const ZdRedirectURLAdmin = Loadable(
  lazy(() => import("./pages/admin/ZdRedirectURL"))
);

const AdminLogin = Loadable(
  lazy(() => import("./pages/admin/AdminLogin"))
);

const Queries = Loadable(
  lazy(() => import("./pages/admin/Queries"))
);

const UsefulLinkAdmin = Loadable(
  lazy(() => import("./pages/admin/UsefulLink"))
);

const Clouditools = Loadable(
  lazy(() => import("./pages/clouditools/Home"))
);

const UserManagementAdmin = Loadable(
  lazy(() => import("./pages/admin/UserManagement"))
);
// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard/queue-management" />,
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <RoleProvider>
          <DashboardLayout children={undefined} />
        </RoleProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "coralogix-query",
        element: <Coralogix />,
      },
      {
        path: "redash-query",
        element: <Redash/>,
      },
      {
        path: "jenkins-query",
        element: <Jenkins/>,
      },
      {
        path: "links-grid",
        element: <UsefulLinks />,
      },
      {
        path: "queue-management",
        element: <QueueManagement />,
      },
      {
        path: "clouditools",
        element: <Clouditools />,
      },
      {
        path: "admin",
        element: <AdminLogin />,
        children: [
          {
            path: "queries",
            element: <Queries />,
          },
          {
            path: "zd-redirect-url",
            element: <ZdRedirectURLAdmin />,
          },
          {
            path: "useful-link",
            element: <UsefulLinkAdmin />,
          },
          {
            path: "user-management",
            element: <UserManagementAdmin />,
          }
        ],
      },
    ],
  },
  {
    path: "admin",
    element: < Navigate to="/dashboard/admin" replace />
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
