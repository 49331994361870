import { Fragment, ReactNode } from "react";
import Login from "../../pages/authentication/Login";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const isAuthenticated = window.localStorage.getItem("user");

  if (!isAuthenticated) {
    return <Login />;
  }

  return <Fragment>{children}</Fragment>;
};

export default AuthGuard;
